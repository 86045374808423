import IconPlanning from "@material-ui/icons/HomeWork";
import IconEnergy from "@material-ui/icons/WbIncandescent";
import IconFirePlace from "@material-ui/icons/Fireplace";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";

const translation: {
    [key: string]: string;
} = {
    LANDING: "Home",
    CONTACT: "Kontakt",
    PERFORMANCES: "Leistungen",
    IMPRESSUM: "Impressum",
    TEAM: "Team",
    JOBS: "Jobs",
    LANDING_TITLE: "Classmann + Tüttenberg",
    LANDING_SUBTITLE: "Ingenieure",
    POSITION: "Position",
    EDUCATION: "Ausbildung",
    QUALIFICATION: "Zusatzqualifikation",
    MENU: "Menu",
};

export const performances: Performances = [
    {
        NAME: "tragwerk",
        TITLE: "Tragwerksplanung",
        MESSAGE:
            "Jede bauliche Anlage muss so erstellt werden, dass sie standsicher und dauerhaft ist. Daher ist eine maßgeschneiderte Tragwerksplanung unerlässlich und stellt einen elementaren Prozess bei der Verwirklichung Ihrer Bauvorhaben dar. Dieser reicht von der Analyse des Standortes und der jeweils spezifischen Gegebenheiten über die Festlegung eines geeigneten Tragsystems bis hin zur Auswahl der verwendeten Baustoffe. Eine wirtschaftliche Konstruktion unter Wahrung einer guten baupraktischen Umsetzbarkeit steht bei uns stets im Vordergrund.",
        POINTS: [
            "Abstimmung mit Ihrem Architekten bezüglich Baustatik",
            "Erstellung des Standsicherheitsnachweises für Ihr Bauvorhaben",
            "Statische Berechnungen für Massivbau",
            "Statische Berechnungen für Holztafel- und Ingenieurholzbau",
            "Erstellung von projektbezogenen Bewehrungsplänen",
            "Prüfung der Standsicherheit bei Umbauten an Bestandsgebäuden",
        ],
        ICON: IconPlanning,
    },
    {
        NAME: "brandwerk",
        TITLE: "Vorbeugender Brandschutz",
        MESSAGE:
            "Bauliche Anlagen müssen gemäß Bauordnungsrecht so angeordnet und beschaffen sein, dass der Entstehung eines Brandes und Ausbreitung von Feuer und Rauch vorgebeugt wird (vgl. MBO § 14). Unter Berücksichtigung der bauordnungsrechtlichen Anforderungen des jeweiligen Bundeslandes, ist für jedes Bauvorhaben ein individuelles Brandschutzkonzept zu erstellen. Maßgebende Parameter sind hier u. a. die Bauwerksnutzung, die Gebäudehöhe und Ausdehnung (i. S. d. Bauordnung). Weiter gilt es zu prüfen, ob spezielle Anforderungen oder Erleichterungen für Sonderbauten Anwendung finden.",
        POINTS: [
            "Beratung im Bereich vorbeugender Brandschutz",
            "Erstellung von Brandschutzkonzepten",
            "Erstellung von Flucht- und Rettungsplänen",
        ],
        ICON: IconFirePlace,
    },
    {
        NAME: "energieberatung",
        TITLE: "Energieberatung und Wärmeschutz",
        MESSAGE:
            "Egal ob Neubau oder Bestand, das Ziel einer möglichst energiesparenden Bauausführung sollte stets zu den Grundbausteinen einer umfassenden Gebäudeplanung gehören. Eine genaue energetische Bewertung und Berechnung nach aktuellen Vorgaben und Bestimmungen ist daher unabdingbar und in vielen Fällen sogar Pflicht. Grundsätzlich gilt die Prämisse:  Je effizienter die Anlagentechnik für Heizung und Warmwasseraufbereitung, desto geringer sind die erforderlichen Maßnahmen zur Wärmedämmung des Gebäudes.",
        POINTS: [
            "Energetische Beratungen nach GebäudeEnergieGesetz (GEG)",
            "Erstellung von Sanierungskonzepten bei Bestandsbauten",
            "Erstellung von Energieausweisen (Bedarfs- und Verbrauchsausweise) ",
            "Wärmebrückenberechnungen",
        ],
        ICON: IconEnergy,
    },
];

export const teamMembers = [
    {
        IMAGE: "/Bild_Christopher_Classmann.webp",
        NAME: "Christopher Classmann (B. Eng.)",
        POSITION: "Inhaber/Geschäftsführer",
        EDUCATION: "Bauingenieur (FH Mainz)",
        QUALIFICATIONS: [
            "Nachweisberechtigt für Standsicherheit",
            "Mitglied der Ingenieurkammer Rheinland-Pfalz",
        ],
    },
    {
        IMAGE: "Bild_Moritz_Tuettenberg.webp",
        NAME: "Moritz Tüttenberg (M. Eng) ",
        POSITION: "Inhaber/Geschäftsführer",
        EDUCATION: "Holzingenieur (FH Aachen)",
        QUALIFICATIONS: ["Ingenieur für vorbeugenden Brandschutz"],
    },
    {
        IMAGE: "/Bild_PLATZHALTER.png",
        NAME: "Jason Keller",
        POSITION: "CAD Konstrukteur",
        EDUCATION: "Bauzeichner (Hochbau)",
    },
];

export const contact = {
    POSTCONTACT: "Postanschrift",
    POSTLINECONTACT: "Classmann & Tüttenberg Ing. GbR",
    POSTLINESTREET: "Industriestr. 1a",
    POSTLINECODE: "55624 Gösenroth",
    PHONE: "Telefon",
    PHONENUMBERONE: "06544 2114889 (Büro)",
    MAIL: "Mail",
    MAILADDRESS: "info@ct-ing.de",
};

export const impressum = {
    COMPANY: "Firmierung",
    COMPANYNAME: "Classmann und Tüttenberg Ingenieure GbR",
    HEADQUARTER: "Sitz der Gesellschaft",
    HEADQUARTERLINEONE: "Industriestr. 1a ",
    HEADQUARTERLINETWO: "55624 Gösenroth",
    REPRESENTED: "Vertreten durch",
    REPRESENTEEONE: "Christopher Classmann ",
    REPRESENTEETWO: "Moritz Tüttenberg",
    TAX: "Steuernummer",
    TAXNUMBER: "09/026/33324",
    INSURANCE: "Berufshaftpflicht",
    INSURANCENAME: "R+V Allgemeine Versicherung AG",
    LIMITINGLIABILITY: "Haftungsbeschränkung für eigene Inhalte",
    LIMITINGLIABILITYCONTENT:
        "Alle Inhalte unseres Internetauftritts wurden mit Sorgfalt und nach bestem Gewissen erstellt. Eine Gewähr für die Aktualität, Vollständigkeit und Richtigkeit sämtlicher Seiten kann jedoch nicht übernommen werden. Gemäß § 7 Abs. 1 TMG sind wir als Dienstanbieter für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich, nach den §§ 8 bis 10 TMG jedoch nicht verpflichtet, die übermittelten oder gespeicherten fremden Informationen zu überwachen. Eine umgehende Entfernung dieser Inhalte erfolgt ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung und wir haften nicht vor dem Zeitpunkt der Kenntniserlangung.",
    COPYRIGHT: "Urheberrecht",
    COPYRIGHTCONTENT:
        "Die auf dieser Webseite veröffentlichten Inhalte und Werke unterliegen dem deutschen Urheberrecht. Jede Art der Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechts bedarf der vorherigen schriftlichen Zustimmung des jeweiligen Urhebers bzw. Autors.",
};

export const jobs = {
    SHORT: "zur Zeit keine offenen Stellen",
    LONG:
        "Derzeit haben wir bei CT Ingenieure keine offenen Stellen zu besetzen. Initiativbewerbungen sind jedoch jederzeit willkommen.",
};

export const getMessage = (key: string) => {
    return translation[key];
};

export type Performances = {
    NAME: string;
    TITLE: string;
    MESSAGE: string;
    POINTS: string[];
    ICON: OverridableComponent<any>;
}[];
